import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This document aims to discuss some of the core concepts of building with Uchiru Components.`}</p>
    <h2>{`Responsive props`}</h2>
    <p>{`It's really easy to set different values for most of our component props based on screen size! We take advantage of `}<a parentName="p" {...{
        "href": "https://github.com/styled-system/styled-system"
      }}>{`styled-system`}</a>{`'s responsive props API in our components.`}</p>
    <pre><code parentName="pre" {...{}}>{`<Button display={['flex', 'flex', 'none']}/>

or

<Text fontSize={[1,1,1,4]}/>
`}</code></pre>
    <h2>{`Providing your own theme`}</h2>
    <p>{`You can provide your own theme to Uchiru Components! There are a few ways of doing this to varying degrees, checkout the `}<a parentName="p" {...{
        "href": "https://style.onlineschool.ru/components/uchiru-theme"
      }}>{`theme docs`}</a>{` for more information.`}</p>
    <h2>{`The `}<inlineCode parentName="h2">{`css`}</inlineCode>{` prop`}</h2>
    <p>{`When push comes to shove and you just `}<em parentName="p">{`really`}</em>{` need to add a custom CSS rule, you can do that with the `}<inlineCode parentName="p">{`css`}</inlineCode>{` prop. Please don't abuse this :)`}</p>
    <pre><code parentName="pre" {...{}}>{`<Box css='border-bottom-right-radius: 0px' />

`}</code></pre>
    <p>{`Please note that you will need to have the `}<strong parentName="p"><a parentName="strong" {...{
          "href": "https://www.styled-components.com/docs/tooling#babel-plugin"
        }}>{`styled-components babel plugin`}</a></strong>{` set up in your project in order to use the `}<inlineCode parentName="p">{`css`}</inlineCode>{` prop.`}</p>
    <h2>{`Types of components`}</h2>
    <p>{`We categorize our components into 3 general types. Building block components, pattern components, and helper components. Understanding how these types of components interact with each other can help you better understand how to get the most out of Uchiru Components.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Building Blocks`}</p>
        <p parentName="li">{`Building block components are components that are basic in their functions and can be used together with other components to build just about any UI. Some examples of building block components are `}<inlineCode parentName="p">{`Box`}</inlineCode>{`, `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{`, `}<inlineCode parentName="p">{`Details`}</inlineCode>{`, and `}<inlineCode parentName="p">{`Link`}</inlineCode>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Pattern Components`}</p>
        <p parentName="li">{`Pattern components are components that are made up of several building block components to represent a commonly used pattern in our UI. Some examples of pattern components are `}<inlineCode parentName="p">{`UnderlineNav`}</inlineCode>{` and `}<inlineCode parentName="p">{`FilterList`}</inlineCode>{`. We plan on expanding our offering of pattern components in the near future.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Helper Components`}</p>
        <p parentName="li">{`Helper components are components that help the user achieve common CSS patterns while maintaining some control over values used. Some examples of helper components are `}<inlineCode parentName="p">{`Flex`}</inlineCode>{`, `}<inlineCode parentName="p">{`Text`}</inlineCode>{`, `}<inlineCode parentName="p">{`Grid`}</inlineCode>{`, and the `}<inlineCode parentName="p">{`Position`}</inlineCode>{` components.`}</p>
      </li>
    </ul>
    <h2>{`The `}<inlineCode parentName="h2">{`as`}</inlineCode>{` prop`}</h2>
    <p>{` The `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop is a feature that all of our components get from `}<a parentName="p" {...{
        "href": "https://www.styled-components.com"
      }}>{`styled-components`}</a>{`. It allows you to pass a HTML tag or another component to a Uchiru Component to be rendered as the base tag of that component along with all of it's styles and props.`}</p>
    <p>{` For example, say you are using a `}<inlineCode parentName="p">{`Button`}</inlineCode>{` component, and you really need to apply `}<inlineCode parentName="p">{`Flex`}</inlineCode>{` styles to it. You can compose `}<inlineCode parentName="p">{`Flex`}</inlineCode>{` and `}<inlineCode parentName="p">{`Button`}</inlineCode>{` like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-.jsx"
      }}>{`<Flex as={Button} href='https://github.com'>Hello</Flex>
`}</code></pre>
    <p>{` This will allow you to use all of the `}<inlineCode parentName="p">{`Button`}</inlineCode>{` props `}<em parentName="p">{`and`}</em>{` all of the `}<inlineCode parentName="p">{`Flex`}</inlineCode>{` props without having to wrap your `}<inlineCode parentName="p">{`Button`}</inlineCode>{` component in another `}<inlineCode parentName="p">{`Flex`}</inlineCode>{` component.`}</p>
    <p>{` `}<strong parentName="p">{`This pattern does have some limitations.`}</strong>{` Usage of the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop can lead to unexpected output. In the example above, if the user had done `}<inlineCode parentName="p">{`<Button as={Flex}/>`}</inlineCode>{` instead, because the `}<inlineCode parentName="p">{`Flex`}</inlineCode>{`'s render method is ultimately applied, and `}<inlineCode parentName="p">{`Flex`}</inlineCode>{` components render `}<inlineCode parentName="p">{`div`}</inlineCode>{`'s, you'll see that the rendered component is a `}<inlineCode parentName="p">{`div`}</inlineCode>{` when ideally you'd like it to be a `}<inlineCode parentName="p">{`button`}</inlineCode>{`. It is also not always clear how the styles in both components will interact and/or override each other.`}</p>
    <p>{`For these reasons, `}<strong parentName="p">{`we recommend only using the `}<inlineCode parentName="strong">{`as`}</inlineCode>{` prop when you cannot achieve the same result by nesting components.`}</strong>{` The `}<inlineCode parentName="p">{`Flex`}</inlineCode>{` / `}<inlineCode parentName="p">{`Button`}</inlineCode>{` example could be done like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-.jsx"
      }}>{`<Flex>
  <Button href='https://github.com'>Hi</Button>
</Flex>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      